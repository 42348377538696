<template>
    <div>
        <div>
            <company-licence-component/>

          <div class="row">
            <div class="col-md-12 m-b-0">
              <router-link class="btn btn-white m-r-5" to="/monitoring"><i class="fa fa-tv fa-fw fa-lg"></i></router-link>
              <router-link class="btn btn-white m-r-5 text-orange" to="/monitoring/sales"><i class="fa fa-chart-bar fa-chart-bar fa-lg text-orange"></i> Vente &amp; Facturation</router-link>
              <router-link class="btn btn-white m-r-5" to="/monitoring/stock"><i class="fa fa-cube fa-fw fa-lg"></i> Stock &amp; Inventaires</router-link>
            </div>
          </div>

            <!--<h1 class="page-header">Comptes clients  <small class="lead">.</small></h1>-->
            <hr class="bg-grey-lighter"/>

          <div class="row m-b-5">
            <div class="col-md-12 m-b-0">
              <router-link class="btn btn-white m-r-5" to="/monitoring/sales"><i class="fa fa-chart-bar fa-fw fa-lg"></i></router-link>
              <router-link class="btn btn-white m-r-5" to="/monitoring/sales/sales-periodic-report-per-warehouse"><i class="fa fa-calendar fa-fw fa-lg"></i> Suivi Vente &amp; Encaissement</router-link>
              <router-link class="btn btn-white m-r-5" to="/monitoring/sales/clients-account-summary"><i class="fa fa-users fa-fw fa-lg"></i> Relevé Comptes Clients</router-link>
              <router-link class="btn btn-white m-r-5" to="/monitoring/sales/invoice-ageing"><i class="fa fa-clock fa-fw fa-lg"></i> Echéances Factures</router-link>
              <router-link class="btn btn-white m-r-5 text-orange" to="/monitoring/sales/sleeping-clients-on-list"><i class="fa fa-bed fa-fw fa-lg text-orange"></i> Clients Dormants</router-link>
              <router-link class="btn btn-white m-r-5" to="/monitoring/sales/sales-orders"><i class="fa fa-file-alt fa-fw fa-lg"></i> Factures Spéciales</router-link>
              <router-link class="btn btn-white m-r-5" to="/monitoring/sales/order-payments"><i class="fa fa-dollar-sign fa-fw fa-lg"></i> Règlements Spéciaux</router-link>
            </div>
          </div>

            <div class="row m-b-5">
                <div class="col-md-12 m-b-0">
                    <router-link class="btn btn-white m-r-5" to="/monitoring/sales/sleeping-clients-on-list"><i
                            class="fa fa-list fa-fw fa-lg"></i> Voir la liste
                    </router-link>
                    <router-link class="btn btn-white m-r-5 text-orange" to="/monitoring/sales/sleeping-clients-on-map">
                        <i class="fa fa-map fa-fw fa-lg text-orange"></i> Voir sur une carte
                    </router-link>
                </div>
            </div>

            <div class="row m-b-5">
                <div class="col-md-12 m-b-0">
                    <router-link class="btn btn-white m-r-5 text-orange" to="/monitoring/sales/sleeping-clients-on-map"><i class="fa fa-map-marker-alt fa-fw fa-lg text-orange"></i> Par Localité</router-link>
                </div>
            </div>

            <div>
              <div class="row text-right m-b-5">
                <div class="col-12">
                  <a href="#" class="btn btn-dark m-l-5" @click="$bvModal.show('formModal')"><i class="fa fa-filter fa-fw fa-lg"></i></a>
                </div>
              </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="invoice">
                          <div class="invoice-company" v-if="queryApiData != null">
                            <h1>{{queryApiData.client_group_name}} - {{queryApiData.location_name}}<br/>
                              <small >Comptes dormants depuis au moins
                                {{queryApiData.day_count_min}} jours.
                                {{queryApiData.total_gps_entries}}
                                Géolocalisés sur {{queryApiData.total_entries}} entrées retrouvées
                                <span class="label" :class="{
                                        'label-danger':queryApiData.gps_rate < 50,
                                        'label-warning':queryApiData.gps_rate >= 50 && queryApiData.gps_rate < 90,
                                        'label-success':queryApiData.gps_rate >= 90
                                        }
                                        "> Taux de pointage GPS : {{queryApiData.gps_rate}}% </span>
                              </small>
                            </h1>
                          </div>
                          <div class="invoice-content">
                                <div class="widget-map widget-map-rounded m-b-30">
                                    <div class="widget-map-body">
                                        <GmapMap
                                                style="width: 100vmin; height: 80vmin"
                                                class="width-full"
                                                v-bind:options="map_options"
                                                :zoom="map_zoom"
                                                :center="map_center"
                                                ref="mapRef">

                                            <GmapMarker
                                                    v-for="(item, index) in clients"
                                                    :key="index"
                                                    :position="{lat:item.lat, lng:item.lng}"
                                                    @click="openInfoWindowTemplate(item)"
                                                    :clickable="true"/>

                                            <GmapInfoWindow
                                                    @closeclick="infoWindow.open=false"
                                                    :position="infoWindow.position"
                                                    :opened="infoWindow.open"
                                                    :options="{maxWidth: 300,pixelOffset: {width: 0, height: -40}}">
                                                <div class="card">
                                                    <div class="card-block">
                                                        <h4 class="card-title m-t-0">{{selectedClient.name}}</h4>
                                                        <h6 class="card-subtitle m-b-10 text-muted">{{selectedClient.phone}}</h6>
                                                        <p class="card-text">{{selectedClient.address_line}}</p>
                                                    </div>
                                                </div>
                                            </GmapInfoWindow>

                                        </GmapMap>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

              <div v-if="pageIndexApiData != null">
                <b-modal
                    centered
                    hide-header
                    hide-footer
                    no-close-on-esc
                    no-close-on-backdrop
                    hide-header-close
                    id="formModal"
                    title="Modal Title"
                    size="md"
                    @show="()=>{
              this.queryApiDataErrorMessage = null;
            }"
                    @hidden="()=>{}">

                  <div class="row ">
                    <p class="col-12 text-right">
                      <a href="javascript:;" class="btn btn-danger" @click="$bvModal.hide('formModal')"><i
                          class="fa fa-times fa-fw fa-lg"></i></a>
                    </p>
                  </div>

                  <h1 class="page-header">Choisir un type de client <small class="lead">.</small></h1>
                  <div class="row" v-if="queryApiDataIsLoading">
                    <div class="col-12 m-b-10">
                      <small class="lead text-warning"><i class="fas fa-spinner fa-pulse"></i> Chargement en cours...</small>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="col-12" v-if="queryApiDataErrorMessage != null">
                      <div class="alert alert-danger">
                        {{ queryApiDataErrorMessage }}
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <form
                          @submit.prevent="queryData">
                        <div class="form-group row">
                          <div class="col-12">
                            <label>Choisir un type de client</label>
                            <v-select label="name" :options="pageIndexApiData.client_groups"
                                      :reduce="item => item.id"
                                      v-model="form.client_group_id"/>
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="col-12">
                            <label>Choisir un code localité</label>
                            <v-select label="name" :options="pageIndexApiData.location_codes"
                                      :reduce="item => item.code"
                                      v-model="form.location_code"/>
                          </div>
                        </div>

                        <div class="form-group row">
                          <div class="col-6">
                            <label>Sommeil Minimal (en jours)</label>
                            <input v-model="form.day_count_min" type="number" name="day_count_min"
                                   class="form-control"
                                   placeholder="Sommeil Minimal (en jours)">
                          </div>
                          <div class="col-6">
                            <label>Sommeil Maximal (en jours)</label>
                            <input v-model="form.day_count_max" type="number" name="day_count_max"
                                   class="form-control"
                                   placeholder="Sommeil Maximal">
                          </div>
                        </div>

                        <div class="form-group row">
                          <div class="col-12">
                            <label>Choisir une date de référence</label>
                            <date-picker
                                v-model="form.date"
                                :config="{
                                    format:'DD-MM-YYYY',
                                    useCurrent:false
                                  }">
                            </date-picker>
                          </div>
                        </div>

                        <div class="form-group row">
                          <!--
                          <div class="col-6">
                            <div class="checkbox checkbox-css checkbox-inline">
                              <input type="checkbox" id="pdf" v-model="form.to_pdf"/>
                              <label for="pdf"><i class="fas fa-download text-warning"></i>
                                Télécharger au format PDF</label>
                            </div>
                          </div>
                          -->
                          <div class="col-6">
                            <div class="checkbox checkbox-css checkbox-inline">
                              <input type="checkbox" id="excel" v-model="form.to_excel"/>
                              <label for="excel"><i class="fas fa-download text-warning"></i>
                                Télécharger au format Excel</label>
                            </div>
                          </div>
                        </div>

                        <div class="form-group row">
                          <div class="col-12 text-right">
                            <button :disabled="queryApiDataIsLoading" type="submit" class="btn btn-dark">
                              <i class="fas fa-spinner fa-spin" v-if="queryApiDataIsLoading"></i>
                              <i class="fas fa-filter" v-else></i> Filtrer
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <!--
                  <div class="row ">
                    <p class="col-12 text-right">
                      <a href="javascript:;" class="btn btn-white m-r-5" @click="()=>{}"><i class="fa fa-comment fa-fw fa-lg"></i></a>
                      <a href="javascript:;" class="btn btn-white m-r-5" @click="()=>{}"><i class="fa fa-cogs fa-fw fa-lg"></i></a>
                    </p>
                  </div>
                  -->
                </b-modal>
              </div>
            </div>
        </div>
        <!--<div class="panel-loader" v-else><span class="spinner-small"></span></div>-->
    </div>
</template>


<script>
    import pageApi from '../../store/api/monitoring/location-sleeping-clients-on-map-dashboard-page-api'
    import LocalStorage from "../../store/local-storage";
    import CompanyLicenceComponent from '../../components/app/base/CompanyLicenceComponent.vue'
    import {gmapApi} from 'vue2-google-maps'

    /*
    var noPOIMapStyles = [
        {
            featureType: "poi",
            stylers: [
                {visibility: "off"}
            ]
        }
    ];
    */

    export default {
        name: 'location-sleeping-clients-on-map-dashboard-page-component',
        components: {
            CompanyLicenceComponent,
        },
        props: {},
        watch: {},
        computed: {
            google: gmapApi
        },
        methods: {
            //Initialization Methods
            loadPageScripts() {
                this.$refs.mapRef.$mapPromise.then(map => {
                    this.map = map;
                    //
                    this.panToMarker();
                });
            },

          formatDateInput (value) {
            if (value) {
              return window.moment(String(value)).format(window.appDefaultDisplayDateTimeFormat)
            }
            return null;
          },

            //Moves the map view port to marker
            panToMarker() {
                if (this.map) {
                    this.map.panTo(this.map_center);
                    //this.map.setZoom(10);
                }
            },

            setMapBounds() {

                if (this.map) {
                    let bounds = new this.google.maps.LatLngBounds();
                    for (var i = 0; i < this.clients.length; i++) {
                        bounds.extend(new this.google.maps.LatLng(this.clients[i].lat, this.clients[i].lng))
                    }
                    this.map.fitBounds(bounds);
                }

            },

            openInfoWindowTemplate(client) {
                this.selectedClient = client;
                this.infoWindow.position = {lat: client.lat, lng: client.lng}
                this.infoWindow.open = true
            },

            //Other Methods
            async getPageIndexApiData() {
                this.pageIndexApiDataIsLoading = true;
                this.pageIndexApiDataErrorMessage = null;
                try {
                    const response = await pageApi.getIndexData();
                    //console.log(response);
                    this.pageIndexApiData = response.data;
                    this.pageIndexApiDataIsLoading = false;
                    this.pageIndexApiDataErrorMessage = null;

                    this.location_codes = response.data.location_codes;
                    this.client_groups = response.data.client_groups;

                } catch (error) {
                    if (error.response) {
                        //Server responded with a status code that is NOT in the HTTP 200 range
                        //console.log("Headers", error.response.headers);
                        //console.log("Status Code", error.response.status);
                        //console.log("Response Data", error.response.data);
                        if (error.response.status === 401 || error.response.status === 403 || error.response.status === 419) {
                            this.pageIndexApiDataIsLoading = false;
                            this.pageIndexApiDataErrorMessage = error.response.data.message;
                            LocalStorage.clearSessionData();
                            this.$router.push('login');
                        } else {
                            this.pageIndexApiDataIsLoading = false;
                            this.pageIndexApiDataErrorMessage = error.response.data.message;
                            //
                            this.$swal({
                                icon: 'error',//'info','success','warning','error','question',
                                toast: true,
                                position: 'bottom-end',
                                timer: 3000,
                                showConfirmButton: false,
                                title: 'HTTP ' + error.response.status,
                                text: error.response.data.message,
                            })
                        }
                    } else {
                        this.pageIndexApiDataIsLoading = false;
                        this.pageIndexApiDataErrorMessage = error.message;
                        //
                        this.$swal({
                            icon: 'error',//'info','success','warning','error','question',
                            toast: true,
                            position: 'bottom-end',
                            timer: 3000,
                            showConfirmButton: false,
                            //title: error.message
                            text: error.message,
                        })
                    }
                }
            },

            async queryData() {
                this.queryApiDataIsLoading = true;
                this.queryApiDataErrorMessage = null;
                this.queryApiDataValidationErrors = [];
                try {
                    const response = await pageApi.queryData({
                        location_code: this.form.location_code,
                        client_group_id: this.form.client_group_id,
                        day_count_min: this.form.day_count_min,
                        day_count_max: this.form.day_count_max,
                        date: this.form.date,
                        to_pdf: this.form.to_pdf,
                        to_excel: this.form.to_excel
                    });
                    //console.log(response);
                    this.queryApiData = response.data;
                    this.queryApiDataIsLoading = false;
                    this.queryApiDataErrorMessage = null;
                    this.queryApiDataValidationErrors = [];

                    this.$bvModal.hide('formModal');

                    this.clients = response.data.clients;
                    this.setMapBounds();

                } catch (error) {
                    if (error.response) {
                        //Server responded with a status code that is NOT in the HTTP 200 range
                        //console.log("Headers", error.response.headers);
                        //console.log("Status Code", error.response.status);
                        //console.log("Response Data", error.response.data);
                        this.queryApiDataIsLoading = false;
                        this.queryApiDataErrorMessage = error.response.data.message;
                        if (error.response.status === 422) {
                            this.queryApiDataValidationErrors = error.response.data.errors;
                        } else {
                            this.queryApiDataValidationErrors = [];
                        }
                    } else {
                        this.queryApiDataIsLoading = false;
                        this.queryApiDataErrorMessage = error.message;
                        this.queryApiDataValidationErrors = [];
                    }
                }
            },
        },
        data: function () {


            return {
                appName: window.appConfig.appName,
                appCurrency: window.appConfig.appCurrency,
                intervalId01: 0,

                location_codes: [],
                client_groups: [],
                form: {
                    location_code: null,
                    client_group_id: null,
                    day_count_min: 7,
                    day_count_max: 30,
                    date: null,
                    to_pdf: false,
                    to_excel: false,
                },

                map: null,
                map_options: {
                    text: 'Default Theme',
                    //styles: noPOIMapStyles,
                    zoomControl: true,
                    mapTypeControl: true,
                    scaleControl: true,
                    streetViewControl: false,
                    rotateControl: true,
                    fullScreenControl: true,
                    disableDefaultUi: false
                },
                map_center: {
                    lat: window.appConfig.mapsCenterLat,
                    lng: window.appConfig.mapsCenterLng
                },
                map_zoom: 8,
                infoWindow: {
                    position: {
                        lat: window.appConfig.mapsCenterLat,
                        lng: window.appConfig.mapsCenterLng
                    },
                    open: false,
                    template: ''
                },
                clients: [],
                selectedClient: {},

                //API Data
                //
                pageIndexApiData: null,
                pageIndexApiDataIsLoading: false,
                pageIndexApiDataErrorMessage: null,

                queryApiData: null,
                queryApiDataIsLoading: false,
                queryApiDataErrorMessage: null,
                queryApiDataValidationErrors: [],
            };
        },
        created: function () {
            this.getPageIndexApiData();
        },
        mounted: function () {
            this.loadPageScripts();
        },
        beforeDestroy: function () {
        },
    }
</script>
